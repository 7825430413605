import request from '../request'


// 获取支持的交易币种
export function favorFind(data) {
	return request({
		url: '/exchange/favor/find',
		method: 'POST',
		data: data
	})
}

// 历史委托
export function orderHistory(data) {
	return request({
		url: '/exchange/order/history',
		method: 'POST',
		data: data
	})
}

// 当前委托
export function orderCurrent(data) {
	return request({
		url: '/exchange/order/current',
		method: 'POST',
		data: data
	})
}

// 取消委托
export function orderCancel(data) {
	return request({
		url: '/exchange/order/cancel/'+data.orderId,
		method: 'POST',
		data:data
	})
}

// 个人中心当前委托
export function perCurrent(data) {
	return request({
		url: '/exchange/order/personal/current',
		method: 'POST',
		data:data
	})
}

// 个人中心历史委托
export function perHistory(data) {
	return request({
		url: '/exchange/order/personal/history',
		method: 'POST',
		data:data
	})
}

// 添加委托订单
export function exchangeAdd(data) {
	return request({
		url: '/exchange/order/add',
		method: 'POST',
		data:data
	})
}

// 添加自选
export function favorAdd(data) {
	return request({
		url: '/exchange/favor/add',
		method: 'POST',
		data:data
	})
}

// 添加自选
export function favorDelete(data) {
	return request({
		url: '/exchange/favor/delete',
		method: 'POST',
		data:data
	})
}