import request from '../request'


// 获取支持的交易币种
export function symbolTrend(data) {
	return request({
		url: '/market/symbol-thumb-trend',
		method: 'POST',
		data: data
	})
}

// 获取币种缩略行情
export function symbolThumb(data) {
	return request({
		url: '/market/symbol-thumb',
		method: 'POST',
		data: data
	})
}


// 获取某交易对详情
export function symbolInfo(data) {
	return request({
		url: '/market/symbol-info',
		method: 'POST',
		data: data
	})
}


// 获取某交易对详情mini
export function plateMini(data) {
	return request({
		url: '/market/exchange-plate-mini',
		method: 'POST',
		data: data
	})
}

// 获取某交易对详情full
export function plateFull(data) {
	return request({
		url: '/market/exchange-plate-full',
		method: 'POST',
		data: data
	})
}

// 查询最近成交记录
export function latestTrade(data) {
	return request({
		url: '/market/latest-trade',
		method: 'POST',
		data: data
	})
}

// 获取币种历史K线
export function marketK(data) {
	return request({
		url: '/market/history',
		method: 'POST',
		data: data
	})
}

// 换算
export function exchangeRate(data) {
	return request({
		url: '/market/exchange-rate/usd-cny',
		method: 'POST',
		data: data
	})
}

// 概获取币种详情述
export function marketCoinInfo(data) {
	return request({
		url: '/market/coin-info',
		method: 'POST',
		data: data
	})
}

// BTC-USDT趋势线
export function marketBtcTrend(data) {
	return request({
		url: '/market/btc/trend',
		method: 'POST',
		data: data
	})
}